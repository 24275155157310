<template>
  <el-col :span="24" class="content-wrapper1">
    <transition>
      <router-view></router-view>
    </transition>
  </el-col>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  },
  methods: {
    href(url) {
      console.log(url);
      //this.$router.push(url);
    }
  }
};
</script>
<style lang="less">
.content-wrapper1 {
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  max-width: 100%;
  background-image: url("assets/imgs/loginBg.jpg");
  background-position: center center;
  background-size: 100% 100%;
  .el-input__inner {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}
</style>
